import * as style from "./Layout.module.scss";

import React from "react";

import { Link } from "gatsby";

import { getNavByName, getPageLang, getSlugToUrl } from "../plugins/nodeTool";

const Layout = ({ children, navigations, location, localizations }) => {
  const headerMenu = getNavByName(navigations, "main");
  const footerMenu = getNavByName(navigations, "footer");

  const lang = getPageLang(location, localizations);

  return (
    <div className="container-fluid  d-flex flex-column vh-100 page px-0">
      <header>
        <div
          className={`container  text-center border align-items-start  ${style.desktopHeader}`}
        >
          <div className="row">
            <div className="col-2">LOGO</div>
            <div className="row col-10">
              {headerMenu.map((item, index) => (
                <div className="col" key={index}>
                  <Link to={getSlugToUrl(item.slug, lang)}>{item.label}</Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={`${style.mobileHeader} container-fluid `}>
          <nav className="navbar">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggleExternalContent"
              aria-controls="navbarToggleExternalContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span>MENU</span>
            </button>
          </nav>
          <div className="collapse" id="navbarToggleExternalContent">
            <div>
              {headerMenu.map((item, index) => (
                <div className="row " key={index}>
                  <Link
                    className=" py-2 rounded"
                    to={getSlugToUrl(item.slug, lang)}
                  >
                    {item.label}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </header>
      <main className="container main  align-items-center">{children}</main>
      <footer className="container  mt-auto ">
        <div className="row">
          <div className="col-lg col-12 order-lg-0 order-1">Copyright</div>
          <div className="col-lg col-12 row order-lg-1 order-0">
            {footerMenu.map((item, index) => (
              <div className="col" key={index}>
                <Link to={getSlugToUrl(item.slug, lang)}>{item.label}</Link>
              </div>
            ))}
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Layout;
