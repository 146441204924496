const defaultLanguage = "en";

const self = (module.exports = {
  getLocalePage: (locale) => (locale === defaultLanguage ? "" : locale),
  getSlugToUrl: (slug, locale) =>
    "/" +
    (self.getLocalePage(locale) !== ""
      ? self.getLocalePage(locale) + "/" + slug
      : slug),
  getPageLang: (location, localizations) => {
    const lang = location.pathname.split("/")[1];
    return localizations.includes(lang)
      ? self.getLocalePage(lang)
      : defaultLanguage;
  },
  getLocalizations: (nodes) => {
    return [...new Set(nodes.map((node) => node.locale))];
  },
  getNavByLanguage: (navigations, lang) =>
    navigations.filter((nav) => nav.locale === lang),
  getNavByName: (navigations, menuName) => {
    const navigation = navigations.find((nav) => nav.name === menuName);
    console.log("navigation", navigation);
    if (navigation) {
      return navigation.navigationItems;
    }
    return [];
  },
});
