import React from "react";

import Layout from "../components/Layout";

const AboutTemplate = ({ pageContext, location }) => {
  console.log("pageContext", pageContext.node);
  const { title, content } = pageContext.node;
  const { navigations, localizations } = pageContext;
  return (
    <div>
      <Layout
        navigations={navigations}
        location={location}
        localizations={localizations}
      >
        <h1 className="text-center">{title}</h1>
        <div className="card">
          <div className="card-body">
            <div>{content}</div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default AboutTemplate;
